import styled from "styled-components";
import { Container, Section } from "../../sdk/Layout";

import { Button } from "../../sdk/Button";
import { Columns, Column, Title, Heading } from "../../sdk/Layout";

export const SectionExtended = styled(Section)`
    min-height: calc(100vh - 550px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Text = styled.div`
    text-align: center;
    padding: 30px 0;
    p {
        margin: 30px 0;
        font-size: 16px;
    }
    i {
        display: inline-block;
        margin-bottom: 20px;
        svg {
            width: 100px;
            color: ${props => props.theme.graySoft};
        }
    } 
    @media (max-width: 375px) {
        h1 {
            font-size: 24px;
        }
        p {
            font-size: 14px;
        }
    } 
`;
